@media (min-width: 900px) {
    .my-description {
        width: 75%;
    }
}
.icon-link svg {
    width: 2.5rem;
    height: 2.5rem;
}
.icon-link path {
    fill: var(--picton-blue);
    transition: fill 0.3s;
}
.icon-link:hover path {
    fill: white;
}
.icon-links-group .icon-link:not(:last-child) {
    margin-right: 2rem;
}
.icon-links-group {
    padding-left: 1rem;
}

nav {
    display: none;
}

nav li {
    list-style-type: none;
}

@media (min-width: 900px) {
    nav {
        display: initial;
    }
}

.nav-link {
    display: flex;
    align-items: center;
}
.nav-link:not(:last-child) {
    margin-bottom: 1rem;
}
.nav-link a {
    color: var(--picton-blue);
}
.nav-link span {
    background-color: var(--picton-blue);
    width: 2rem;
    height: 3px;
    display: inline-block;
    transition: width 0.2s;
}

.nav-link.active span {
    background-color: white;
    width: 4rem;
}
.nav-link.active a {
    color: white;
}
