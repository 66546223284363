.flipcard {
    --height: 10rem;
    perspective: 150rem;
    -moz-perspective: 150rem;
    position: relative;
    height: var(--height);
    width:  var(--height);
}

.flipcard__side {
    height: var(--height);
    transition: all 0.8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    backface-visibility: hidden;
    border-radius: 3px;
    overflow: hidden;
}

.flipcard__side--front {
    background-color: white;
}

.flipcard__side--back {
    background-color: black;
    transform: rotateY(180deg);
}

.flip .flipcard__side--front {
    transform: rotateY(-180deg);
}
.flip .flipcard__side--back {
    transform: rotateY(0);
}

.Question2-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
}

.flipcard__shape path {
    stroke: white;
}
