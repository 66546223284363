.icon-container {
    padding-top: 10rem;
}

.icon-container svg {
    width: 14rem;
    height: 14rem;
}

.input-group {
    position: relative;
}

.control-input {
    border: var(--border-thick);
    border-radius: 10px;
    padding: 1rem 2rem;
    font-size: 2rem;
}

.hint {
    position: absolute;
    top: 1rem;
    right: 1rem;
}
