h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--white);
    /* text-shadow: var(--text-shadow); */
    /* background-image: linear-gradient(to right, var(--white), var(--non-photon-blue));
    background-clip: text;
    -webkit-background-clip: text; */
    /* color: transparent; */
    font-family: 'JosefinSans-SemiBold', Arial, Helvetica, sans-serif;
}

h1 {
    font-size: 4rem;
}
h2 {
    font-size: 2.5rem;
}
h3 {
    font-size: 2rem;
}
h4 {
    font-size: 1.75rem;
}
h5 {
    font-size: 1.5rem;
}
h6 {
    font-size: 1.25rem;
}

/* PHONE */
@media (min-width: 600px) {
}

/* TABLET PORTRAIT */
@media (min-width: 900px) {
}

/* TABLET LANDSCAPE */
@media (min-width: 1200px) {
}

/* TABLET BIG DESKTOP */
@media (min-width: 3000px) {
    h1 {
        font-size: 4rem;
    }
    h3 {
        font-size: 3rem;
    }
}
