p,
span,
li {
    font-family: 'Inter-Regular', Helvetica, Arial, sans-serif;
    color: var(--picton-blue);
    font-size: 1.2rem;
}

p {
    line-height: 1.5;
}

a {
    color: var(--white);
    transition: color 0.3s;
}
a:hover {
    color: var(--non-photon-blue);
}

.dynamic-text {
    color: var(--javascript-yellow);
    cursor: pointer;
}

/* PHONE */
@media (min-width: 600px) {
}

/* TABLET PORTRAIT */
@media (min-width: 900px) {
}

/* TABLET LANDSCAPE */
@media (min-width: 1200px) {
}

/* TABLET BIG DESKTOP */
@media (min-width: 3000px) {
}
