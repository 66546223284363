.homepage-container {
    --padding-y: 4rem;

    padding: var(--padding-y) 2rem;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

main > section {
    scroll-margin-top: var(--padding-y);
}

header {
    margin-bottom: 6rem;
}

.section-header {
    position: sticky;
    top: 0;

    background-color: #0f172a;
    padding: 3rem 0;
    margin-bottom: 1rem;
    z-index: 1;
}

/* TABLET PORTRAIT */
@media (min-width: 900px) {
    .section-header {
        display: none;
    }

    .homepage-container {
        --padding-y: 6rem;

        padding: 0 6rem;
        display: flex;
        gap: 2rem;
    }

    .col {
        padding: var(--padding-y) 0;
        width: 50%;
    }

    header {
        position: sticky;
        top: 0;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        margin-bottom: 0;
        max-height: 100vh;
    }
}

.mouse-gradient {
    position: fixed;

    inset: 0;

    z-index: 10;
    pointer-events: none;
    background: radial-gradient(600px circle at 0px 0px, var(--non-photon-blue-rgb), transparent 80%);
}

/* .scrolling-image-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
} */

/* .scrolling-image {
    opacity: 10%;
    height: 100%;
    background: url('../../media/img/scrolling-image.png') repeat;
    width: 7680px;
    animation: slide 120s linear infinite;
} */

/* @keyframes slide {
    0% {
        transform: translate(0);
    }
    100% {
        transform: translate(-3840px); 
    }
} */
