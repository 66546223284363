.button {
    position: relative;
    transform: skew(-8deg);
    background-color: var(--delft-blue);
    border: var(--border);
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
    backface-visibility: hidden;

    transition: all 0.3s;
}

.button > * {
    display: inline-block;
}

.button:hover {
    transform: translateY(-8px) skew(-8deg);
}

.button:active {
    transform: translateY(-5px) skew(-8deg);
}

.button:disabled {
    background-color: var(--disabled);
}

.button__text-group {
    padding: 1rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.button__text-group__title {
    color: white;
    backface-visibility: hidden;
}

.button__text-group__sub-title {
    color: white;
    margin-top: 0.25rem;
    backface-visibility: hidden;
}

.success {
    background-color: var(--yellow-green);
}
.primary {
    background-color: var(--bice-blue);
}

.button__icon-wrapper {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%) skew(8deg);
}

.button-icon {
    background-color: var(--yellow-green);
    border: var(--border-thick);
    border-radius: 10px;
    padding: 1rem;
    cursor: pointer;
}

.button-icon__svg {
    width: 30px;
    height: 30px;
}

.text-button {
    background-color: transparent;
    border: 0;
    display: block;
    position: relative;
    padding: 0.2em 0;
}

.text-button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: white;
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
}

.text-button::after {
    opacity: 1;
    transform: scale(0);
    transform-origin: center;
}

.text-button:hover::after,
.text-button:focus::after {
    transform: scale(1);
}
