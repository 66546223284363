@import './variables.css';
@import './utils.css';
@import './headers.css';
@import './containers.css';
@import './text.css';
@import './input.css';

@font-face {
    font-family: 'NougatExtraBlack';
    src: local('NougatExtraBlack'), url('../fonts/Nougat-ExtraBlack.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-Regular';
    src: local('Inter-Regular'), url('../fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'JosefinSans-SemiBold';
    src: local('JosefinSans-SemiBold'), url('../fonts/JosefinSans-SemiBold.ttf') format('truetype');
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: calc(10px * var(--text-multiplier));
    box-sizing: border-box;
}

body {
    margin: 0;
    min-height: 100vh;
    height: 1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* #root {
    height: 100%;
    display: flex;
} */

.app-background {
    /* background-color: var(--indigo-dye); */
    /* rgb(15,23,42) */
    background-color: #0f172a;
    /* background-color: var(--indigo-dye); */
}

/* .radial-gradient-blue {
    background: radial-gradient(
        circle,
        rgba(var(--non-photon-blue-rgb), 1) 0%,
        rgba(var(--pale-azure-rgb), 1) 10%,
        rgba(var(--picton-blue-rgb), 1) 20%,
        rgba(var(--bice-blue-rgb), 1) 40%,
        rgba(var(--indigo-dye-rgb), 1) 78%
    );
} */

a {
    text-decoration: none;
}

input {
    outline: 0;
}
