.modal {
    --spacing: 24px;
    --paddingX: 2rem;
    --paddingY: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 100;
}

.modal__close {
    display: block;
    position: fixed;
    background: rgba(255, 255, 255, 0.5);
    border: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal__inner {
    overflow: hidden;
    position: relative;
    width: calc(var(--spacing) * 36);
    max-width: 600px;
    border-radius: 5px;
    border: var(--border);
    background-color: #ffffff;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 20px 32px -8px;
}

.modal__title {
    background-color: var(--picton-blue);
    padding: var(--paddingY) var(--paddingX);
    text-align: center;
    border-bottom: 10px solid rgba(var(--indigo-dye-rgb), 0.5);
}

.modal__content {
    border-top: var(--border);
    background-color: var(--bice-blue);
    padding: var(--paddingY) var(--paddingX);
    border-bottom: 10px solid var(--picton-blue);
}

.modal__close-btn {
    position: absolute;
    top: var(--spacing);
    right: var(--spacing);
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
}

.modal__close-btn:hover svg {
    opacity: 1;
}
