.shiny-bar {
    position: absolute;
    height: 0.5rem;
    width: calc(100% + 6px);
    left: 0;
}

.top {
    top: 0;
}
.bottom {
    bottom: 0;
}

.lighter {
    background-color: rgba(255, 255, 255, 0.2);
}
.darker {
    background-color: rgba(0, 0, 0, 0.2);
}