/* POSITIONING */
.flex {
    display: flex;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.a-center {
    align-items: center;
}

.j-center {
    justify-content: center;
}
.space-around {
    justify-content: space-around;
}
.space-between {
    justify-content: space-between;
}

/* SPACING */
.mt-1 {
    margin-top: 1rem;
}
.mt-2 {
    margin-top: 2rem;
}
.mt-3 {
    margin-top: 3rem;
}
.mt-4 {
    margin-top: 4rem;
}
.mt-5 {
    margin-top: 5rem;
}

.mr-1 {
    margin-right: 1rem;
}
.mr-2 {
    margin-right: 2rem;
}
.mr-3 {
    margin-right: 3rem;
}
.mr-4 {
    margin-right: 4rem;
}
.mr-5 {
    margin-right: 5rem;
}

.mb-1 {
    margin-bottom: 1rem;
}
.mb-2 {
    margin-bottom: 2rem;
}
.mb-3 {
    margin-bottom: 3rem;
}
.mb-4 {
    margin-bottom: 4rem;
}
.mb-5 {
    margin-bottom: 5rem;
}
.mb-6 {
    margin-bottom: 6rem;
}
.mb-7 {
    margin-bottom: 7rem;
}
.mb-8 {
    margin-bottom: 8rem;
}

/* SHADING - font should be at least 1.5 rem to look good */
.text-shadow {
    text-shadow: var(--text-shadow);
}

/* DISPLAY */
.d-block {
    display: block;
}

/* SIZE */
.h-100 {
    height: 100%;
}
.h-75 {
    height: 75%;
}
.h-50 {
    height: 50%;
}

.w-100 {
    width: 100%;
}
.w-75 {
    width: 75%;
}
.w-50 {
    width: 50%;
}

/* TEXT */
.text-center {
    text-align: center;
}

/* Z-INDEX */
.front {
    z-index: 1;
}
