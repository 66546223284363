.job {
    position: relative;
    display: block;
}
.job:not(:last-child) {
    margin-bottom: 2rem;
}
.job:hover .job__background {
    background-color: rgba(var(--pale-azure-rgb), 0.05);
}
.job:hover .job__title {
    color: var(--non-photon-blue);
}
.job:hover .external-link {
    transform: translate(3px, -3px);
}
.job:hover .external-link path {
    stroke: var(--non-photon-blue);
}

.job__title {
    transition: color 0.3s;
}

.job__background {
    position: absolute;
    top: -1rem;
    right: -1.5rem;
    bottom: -1rem;
    left: -1.5rem;

    border-radius: 1rem;
    z-index: -1;
    transition: background-color 0.2s ease-in;
}

.job__length {
    display: inline-block;
}

.job__technologies__skill {
    display: inline-block;
    padding: 0.5rem 1rem;
    border: 2px solid var(--pale-azure);
    color: var(--pale-azure);
    border-radius: 30px;
    background-color: var(--indigo-dye);

    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
}

.external-link {
    width: 1.5rem;
    height: 1.5rem;
    transition: transform 0.3s;
    transform: translate(0, 0);
}

.external-link path {
    stroke-width: 4px;
    stroke: white;
    transition: stroke 0.3s;
}
