:root {
    /* COLORS */
    --white: #ffffff;

    --indigo-dye: #133c55;
    --indigo-dye-rgb: 19, 60, 85;

    --bice-blue: #386fa4;
    --bice-blue-rgb: 56, 111, 164;

    --picton-blue: #59a5d8;
    --picton-blue-rgb: 89, 165, 216;

    --pale-azure: #84d2f6;
    --pale-azure-rgb: 132, 210, 246;

    --non-photon-blue: #91e5f6;
    --non-photon-blue-rgb: 145, 229, 246;

    --javascript-yellow: #f0db4f;

    /* BUTTONS */
    --delft-blue: #313c52;
    --delft-blue-rbg: 49, 60, 82;

    --yellow-green: #83d725;
    --yellow-green-rgb: 131, 215, 37;

    --disabled: #395c6b;
    --disabled-rgb: 57, 92, 107;

    /* TEXT */
    --text-multiplier: 1;
    --text-shadow: -1px 0 black, 2px 4px black, 1px 0 black, 0 -1px black;

    /* MEDIA QUERIES */
    --mq-phone: 600px;
    --mq-tablet-portrait: 900px;
    --mq-tablet-landscape: 1200px;
    --mq-big-desktop: 3000px;

    /* BORDERS */
    --border-radius: 10px;
    --border: 3px solid black;
    --border-thick: 5px solid black;

    /* Z-INDEX */
    --front: 1;
    --modal: 100;
}

/* PHONE */
@media (min-width: 600px) {
    :root {
        --text-multiplier: 1.2;
    }
}

/* TABLET PORTRAIT */
@media (min-width: 900px) {
    :root {
        --text-multiplier: 1.3;
    }
}

/* TABLET LANDSCAPE */
@media (min-width: 1200px) {
    :root {
        --text-multiplier: 1.4;
    }
}

/* TABLET BIG DESKTOP */
@media (min-width: 3000px) {
    :root {
        --text-multiplier: 1.6;
    }
}
