.page-content {
    padding: 1rem 2rem;
    display: flex;
}

.page-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* PHONE */
@media (min-width: 600px) {
}

/* TABLET PORTRAIT */
@media (min-width: 900px) {
}

/* TABLET LANDSCAPE */
@media (min-width: 1200px) {
    .page-content {
        max-width: 120rem;
        margin-left: auto;
        margin-right: auto;
    }
}

/* TABLET BIG DESKTOP */
@media (min-width: 3000px) {
}
